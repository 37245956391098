import { cookieHelper } from "./cookie";

const alertId = 'global-alert';

export const setupAlertDismissal = () => {
	const alert = document.getElementById(alertId);
	if (null == alert) {
		console.error('No global alert');
		return;
	}
	$(alert).on('closed.bs.alert', function () {
		var cookies = new cookieHelper();
		cookies.set("alert-dismissed", true, 1);
	  })
};