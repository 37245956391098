const debounce = (fn) => {
	let frame;

	return (...params) => {
		if (frame) {
			cancelAnimationFrame(frame);
		}

		frame = requestAnimationFrame(() => {
			fn(...params);
		});
	};
};

const storeScroll = () => {
	document.documentElement.dataset.scroll = window.scrollY;
};

/**
 * @see https://css-tricks.com/styling-based-on-scroll-position/
 */
export const watchScroll = () => {
	document.addEventListener('scroll', debounce(storeScroll), {
		passive: true,
	});

	storeScroll();
};

const checkToggleNavigationExpandedClass = (e) => {
	const header = jQuery('#navigation'),
		navigationExpandedClass = 'expanded',
		linkTargets = '.nav-link:not(.nav-link--toggle)[aria-expanded="true"]';
	if (header.find(linkTargets).length > 0) {
		header.addClass(navigationExpandedClass);
	} else {
		header.removeClass(navigationExpandedClass);
	}
};

export const watchNavigationOverlay = () => {
	const collapsibles = ['#navbar-slideout', '#navbar-search'];
	collapsibles.forEach((collapse) => {
		jQuery(collapse).on(
			'hidden.bs.collapse',
			checkToggleNavigationExpandedClass
		);
		jQuery(collapse).on(
			'shown.bs.collapse',
			checkToggleNavigationExpandedClass
		);
	});
	const overlay = document.getElementById('navigation-overlay');
	overlay.addEventListener('click', () => {
		collapsibles.forEach((collapse) => {
			jQuery(collapse).collapse('hide');
		});
	});

};
