const mediaModalId = 'global-media-modal';
const mediaModalDropTargetId = 'global-media-modal-drop';
const mediaElementClassName = 'embed-responsive-item';
export const selector = '[data-toggle="media-modal"]';

export const setupMediaModal = () => {
	const elements = document.querySelectorAll(selector);
	if (elements.length) {
		const modal = document.getElementById(mediaModalId),
			target = document.getElementById(mediaModalDropTargetId);
		if (null == modal || null == target) {
			console.error('Missing media modal');
			return;
		}
		$(modal).on('hidden.bs.modal', e => {
			while (target.firstChild) {
				target.removeChild(target.firstChild);
			}
		});
		Array.from(elements).forEach(element => setupMediaModalToggle(element, modal, target));
	}
};

export const setupMediaModalToggle = (element, modal, target) => {
	element.onclick = event => {
		event.preventDefault();
		const { type, srcset } = element.dataset;
		target.appendChild(makeMediaElement(type, srcset));
		$(modal).modal('show');
	};
};

const makeMediaElement = (type, src) => {
	const element = document.createElement(type);
	element.classList.add(mediaElementClassName);
	if ('iframe' === type) {
		element.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
		element.setAttribute('allowfullscreen', '');
		element.setAttribute('src', src);
	} else {
		element.setAttribute('autoplay', true);
		src.split(',').forEach(src => {
			const source = document.createElement('source');
			source.setAttribute('src', src);
			element.appendChild(source);
		});
	}
	return element;
};
