import { setupAlertDismissal } from './alert';
import { setupMediaModal } from './media';
import { watchNavigationOverlay, watchScroll } from './navigation';
//import { setupScrollSpy } from './scrollSpy';

document.addEventListener('DOMContentLoaded', () => {
	watchScroll();
	watchNavigationOverlay();
	// will be replaced by web-component bs-scroll-spy
	//setupScrollSpy();
	setupMediaModal();
	setupAlertDismissal();

	const socialShare = document.querySelector('#social-share #share-expand');
	$(socialShare).on('show.bs.collapse', () => {
		socialShare.classList.add('show');
	});
});
